.authlayout {
  height: 100vh;
  overflow-x: hidden;
  background: #1d1927;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.authlayout_logo {
  margin-left: "25px";
  /* border-radius: 50%; */
  /* padding: 2em; */
  /* margin-bottom: 4em; */
  /* -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite; */
}

.authlayout_navbar {
  background-color: #1e2a35;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.authlayout_logo img {
  max-width: 175px;
  margin-left: 25px;
}

.authlayout_navbar a {
  text-decoration: none;
  color: white;
  margin-right: 50px;
  border: 1px solid white;
  /* Add border to the link */
  padding: 5px 10px;
}

.authlayout_actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  margin-top: 2em;
  padding: 0 2px;
}

.authlayout_actions p {
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 300;
}

.authlayout_actions-l {
  color: #f27a48;
}

.authlayout_actions-r {
  color: #bc3f9a;
}

.authMarketing {
  flex: 1;
  height: 100vh;
  max-width: 800px;
  /* Increased size */
  margin: 50px auto;
  /* Add margin from top/bottom */
  background-color: #2a2d3e;
  padding: 40px;
  /* Add more padding for spacing inside */
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  /* Center text inside the div */
  height: auto;
  /* Ensure it adjusts to content height */
}

.authMarketing h2 {
  margin-bottom: 20px;
  /* Increased space between heading and content */
  color: #f27a48;
}

.authMarketing p {
  margin-bottom: 25px;
  /* Increased space between paragraph and list */
}

.authMarketing ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 25px;
  /* Increased margin for better spacing */
}

.authMarketing a {
  display: inline-block;
  text-decoration: none;
  color: #1e2a35;
  background-color: #f27a48;
  padding: 15px 20px;
  /* Larger button */
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
}

.authlayout_footer {
  background-color: #f2f2f2;
  text-align: center;
  padding: 3px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.authlayout_footer p {
  color: #333;
  margin: 0 0 10px 0;
  font-size: 14px;
}

.image-slider {
  text-align: center;
  margin-top: 20px;
  /* Add space above the image */
}

.slider-image {
  width: 100%;
  max-height: 300px;
  /* Adjust as necessary */
  object-fit: contain;
  display: block;
  /* Ensure the image is treated as a block-level element */
}

.authMarketing img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.navigator {
  margin-top: 10px;
  /* Add space between image and navigator */
  font-size: 16px;
  color: #333;
  /* Set a visible text color */
  font-weight: bold;
  background-color: rgba(151, 142, 142, 0.5);
  /* Optional: add background color for contrast */
  padding: 5px 10px;
  display: inline-block;
  /* Make it inline with padding */
  border-radius: 5px;
  /* Optional: rounded corners for navigator */
}

.image-container {
  padding-top: 20px;
  /* Add space to the top */
  padding-bottom: 20px;
  /* Add space to the bottom */
  position: relative;
  /* Ensure the image is contained */
}

@-webkit-keyframes float {
  0% {
    -webkit-box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  50% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  75% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  100% {
    -webkit-box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes float {
  0% {
    -webkit-box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  50% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  75% {
    -webkit-box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    box-shadow: 0 25px 15px 0 rgba(255, 133, 173, 0.2);
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  100% {
    -webkit-box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    box-shadow: 0 5px 15px 0 rgba(252, 222, 255, 0.6);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (min-width: 1200px) {
  .authlayout_actions {
    max-width: 370px;
  }
}

@media (max-width: 768px) {
  .authlayout_logo {
    width: 35%;
    /* Adjust the width as needed */
    float: left;
    /* border-radius: 50%; */
    /* padding: 2em; */
    /* margin-bottom: 4em; */
    /* -webkit-animation: float 6s ease-in-out infinite;
               animation: float 6s ease-in-out infinite; */
  }

  .authlayout_link {
    width: 60%;
    float: right;
    text-align: right;
  }

  .authInputArea h1 {
    text-align: center;
  }
}


/* Free Tools Section */
.free-tools-section {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

.divider {
  position: relative;
  text-align: center;
  margin: 1.5rem 0;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 30px);
  height: 1px;
  background-color: #dbe0e6;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background-color: white;
  padding: 0 15px;
  color: #60758a;
  font-size: 0.875rem;
}

.free-tools-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  border: 1px solid #dbe0e6;
  border-radius: 0.75rem;
  text-decoration: none;
  color: #111418;
  transition: all 0.2s ease;
  margin: 0 1rem;
}

.free-tools-link:hover {
  background-color: #f8f9fa;
  border-color: #0d7cf2;
  color: #111418;
  text-decoration: none;
}

.free-tools-link .tools-icon {
  font-size: 1.5rem;
  color: #0d7cf2;
}

.free-tools-link h4 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
}

.free-tools-link p {
  font-size: 0.875rem;
  color: #60758a;
  margin: 0.25rem 0 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .free-tools-link {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }
}
