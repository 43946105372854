@import "../../components/variables/variables.scss";
@import "../../../public/assets/css/main.scss";

.reset_p {
  text-align: center;
  color: $yellow;
  font-weight: 300;
  margin-top: 3em;
  text-transform: capitalize;
  cursor: pointer;
}
