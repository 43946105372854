/* Base chart card styles */
.chart-card {
  height: 100%;
}

.chart-card .card-body {
  padding: 1.5rem;
}

/* Skeleton loading animation */
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.chart-skeleton {
  height: 100%;
  min-height: 400px;
}

.skeleton-header {
  margin-bottom: 1.5rem;
}

.skeleton-title {
  height: 24px;
  width: 200px;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.skeleton-chart {
  height: calc(100% - 40px);
  min-height: 360px;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s infinite linear;
  border-radius: 8px;
}

/* Error state */
.chart-error {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chart-error p {
  color: #dc3545;
  margin-bottom: 1rem;
}

/* Custom tooltip */
.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  padding: 0.75rem !important;
}
