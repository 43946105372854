.tools-container {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.tools-navbar {
  background-color: #1e2a35 !important;
  padding: 0.5rem 1rem;
}

.tools-logo img {
  height: 40px;
}

.tools-link {
  color: white !important;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.tools-link:hover {
  color: #3498db !important;
  text-decoration: none;
}

.tools-nav-bar {
  background-color: #1e2a35;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tools-nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.75rem 0;
}

.tools-nav-link {
  color: white;
  text-decoration: none;
  padding: 0.75rem 1rem;
  margin: 0 0.25rem;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.tools-nav-link:hover,
.tools-nav-link.active-tool {
  background-color: rgba(52, 152, 219, 0.2);
  color: #3498db;
  text-decoration: none;
}

.tools-nav-link.active-tool {
  border-bottom: 2px solid #3498db;
}

.tools-dropdown-toggle {
  background-color: #1e2a35;
  color: white !important;
  border: none;
  padding: 0.75rem 1rem;
  width: 100%;
  text-align: left;
  position: relative; /* Added for ::after pseudo-element */
}

.tools-dropdown-toggle::after {
  content: "\279C"; /* Unicode for a down arrow */
  float: right;
  margin-top: 0.5rem;
}

.tools-dropdown-toggle:hover,
.tools-dropdown-toggle:focus {
  background-color: #2c3e50;
  color: #3498db !important;
}

.dropdown-menu {
  width: 100%;
  background-color: #1e2a35;
  border: none;
  padding: 0;
}

.dropdown-item {
  padding: 0;
}

.dropdown-item .tools-nav-link {
  display: block;
  width: 100%;
  text-align: left;
  color: white;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #2c3e50;
}

.dropdown-item:hover .tools-nav-link,
.dropdown-item:focus .tools-nav-link {
  color: #3498db;
}

@media (max-width: 767px) {
  .tools-navbar {
    flex-direction: column;
    align-items: center;
  }

  .tools-logo {
    margin-bottom: 1rem;
  }

  .tools-nav-bar {
    padding: 0.5rem 0;
  }

  .tools-nav-link.active-tool {
    border-bottom: none;
    border-left: 2px solid #3498db;
  }
}

/* Transaction Table Styles */
.table {
  margin-bottom: 0;
}

.table > :not(caption) > * > * {
  padding: 1.25rem 1rem;
}

.table tbody tr {
  vertical-align: middle;
}

.table td {
  font-size: 1.1rem;
}

.table th {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #6c757d;
}

.table td .badge {
  font-size: 1rem;
  padding: 0.6rem 1rem;
  font-weight: 500;
}

.table td svg {
  margin-right: 0.5rem;
  vertical-align: -0.125em;
  font-size: 1.2rem;
}

.transaction-icon {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}
