/* Public Tools Layout Styles */
.public-tools-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: "Work Sans", "Noto Sans", sans-serif;
}

/* Navbar styles */
.navbar {
  background-color: white;
  border-color: #f0f2f5 !important;
}

.navbar .nav-link {
  color: #111418 !important;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  letter-spacing: -0.01em;
}

.navbar .nav-link:hover {
  color: #0d7cf2 !important;
}

.navbar-brand img {
  max-height: 32px;
  width: auto;
}

/* Back link styles */
.back-link {
  color: #60758a;
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5rem 0;
  transition: color 0.2s ease;
  display: inline-flex;
  align-items: center;
}

.back-link:hover {
  color: #0d7cf2;
  text-decoration: none;
}

/* Button styles */
.btn {
  font-size: 1.125rem;
}

.btn-primary {
  background-color: #0d7cf2;
  border-color: #0d7cf2;
  font-weight: 700;
  letter-spacing: -0.01em;
  padding: 0.625rem 1.25rem;
  border-radius: 0.75rem;
}

.btn-primary:hover {
  background-color: #0b6ad0;
  border-color: #0b6ad0;
}

.btn-light {
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  color: #111418;
  font-weight: 700;
  letter-spacing: -0.01em;
  padding: 0.625rem 1.25rem;
  border-radius: 0.75rem;
}

.btn-light:hover {
  background-color: #e4e6e9;
  border-color: #e4e6e9;
  color: #111418;
}

/* Utility classes */
.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

/* Footer styles */
.footer {
  background-color: white;
  color: #111418;
}

.footer .nav-link {
  color: #60758a;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.footer .nav-link:hover {
  color: #111418;
}

/* Page content */
.page-content {
  flex: 1;
}

/* Auth Layout Navbar Styles */
.authlayout_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: #1e2b3a;
}

.authlayout_logo img {
  height: 32px;
  width: auto;
}

.authlayout_link a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.authlayout_link a:hover {
  color: #fff;
}

/* Card hover effects */
.tool-card {
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tool-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .authlayout_navbar {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .authlayout_logo {
    margin-bottom: 0.5rem;
  }

  .footer {
    text-align: center;
  }

  .footer .nav {
    justify-content: center !important;
    margin-top: 1rem;
  }
}
