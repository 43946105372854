/* Font imports */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700;900&family=Noto+Sans:wght@400;500;700;900&display=swap");

/* Custom styles for PublicTools component */
.public-tools {
  font-family: "Work Sans", "Noto Sans", sans-serif;
  min-height: 100vh;
  background-color: white;
}

/* Header styles */
.navbar {
  border-color: #f0f2f5 !important;
}

.navbar .nav-link {
  color: #111418 !important;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Content styles */
.max-w-960 {
  max-width: 960px;
}

.h2 {
  color: #111418;
  font-size: 2rem;
  letter-spacing: -0.033em;
}

/* Card styles */
.card {
  border-color: #dbe0e6 !important;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card-body {
  color: #111418;
}

.card-body .text-muted {
  color: #60758a !important;
}

/* Premium badge styles */
.premium-badge {
  background-color: #fef3c7 !important;
  color: #92400e !important;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
}

/* Features list styles */
.features-list {
  border-top: 1px solid #dbe0e6;
  padding-top: 0.75rem;
}

.features-list .text-primary {
  color: #0d7cf2 !important;
}

/* Utility classes */
.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

/* Button styles */
.btn-primary {
  background-color: #0d7cf2;
  border-color: #0d7cf2;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
}

.btn-light {
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  color: #111418;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
}

/* Icon styles */
.size-4 {
  width: 1rem;
  height: 1rem;
}
