@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: "Prompt";
}

img {
  max-width: 100%;
  width: 100%;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.reset_p {
  text-align: center;
  color: #fbc02d;
  font-weight: 300;
  margin-top: 3em;
  text-transform: capitalize;
  cursor: pointer;
}
