.input {
  margin: 1.5em 0;
}

.input text {
  font-family: Arial, sans-serif;
}

.input label {
  position: relative;
  font-size: 1rem;
}

.input label input {
  flex: 1;
  min-width: 0;
  display: block;
  width: 370px;
  padding: 8px 15px !important;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2a35;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input label span {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
  color: #99979e;
  transition: all 0.3s ease;
  font-weight: 300;
  font-size: 1.3rem;
  font-family: sans-serif;
}

.input label input:not(:placeholder-shown) + span {
  color: transparent;
  transform: translate3d(0, -6px, 0) scale(0.75);
}

.input label input:focus {
  border: 2px solid #f96a8b;
}

.input label input:focus + span {
  top: -15px;
  font-size: 15px;
  background: linear-gradient(to right, #fbc02d, #f96a8b);
  border-radius: 4px;
  padding: 0 5px;
  color: #fff;
}

.input label .input_icon {
  color: #1d1927;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(92%, -50%);
  width: 100%;
  cursor: pointer;
}

.toast-failed {
  background: #f96a8b !important;
  color: #fff;
}

.toast-success {
  background: #009688 !important;
  color: #fff;
}

input[type="text"]:disabled {
  color: #000;
}

@media (min-width: 1200px) {
  .input label input {
    width: 370px;
  }
}
