.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  
  .step {
    text-align: center;
    flex: 1;
    position: relative;
  }
  
  .step:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -50%;
    height: 4px;
    width: 100%;
    background: #ccc;
    z-index: 0;
  }
  
  .step.active:not(:last-child)::after {
    background: #4caf50;
  }
  
  .step-number {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background: #ccc;
    color: white;
    font-weight: bold;
    margin: 0 auto 8px;
    z-index: 1;
    position: relative;
  }
  
  .step.active .step-number {
    background: #4caf50;
  }
  
  .step-label {
    font-size: 14px;
    color: #333;
  }
  