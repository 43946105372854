.transaction-row {
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    transform 0.1s ease-in-out;
}

.transaction-row:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
  transform: translateY(-1px);
}

.transaction-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badge {
  transition: all 0.2s ease-in-out;
}

.badge:hover {
  transform: scale(1.05);
}
