/* Base card styles */
.metric-card {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.metric-card:hover {
  transform: translateY(-5px);
}

.metric-icon {
  opacity: 0.8;
}

/* Skeleton loading animation */
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.metric-card-skeleton {
  padding: 0.5rem 0;
}

.skeleton-title,
.skeleton-value {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.skeleton-title {
  height: 12px;
  width: 60%;
  margin-bottom: 1rem;
}

.skeleton-value {
  height: 24px;
  width: 80%;
}

/* Error state */
.metric-card-error {
  text-align: center;
  padding: 1rem;
}

.metric-card-error p {
  color: #dc3545;
  margin-bottom: 0.5rem;
}

/* Hover effects */
.metric-card .card-body {
  padding: 1.5rem;
}
