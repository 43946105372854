@import "variables";

@import "@vonagevolta/volta2/dist/css/volta.min.css";
@import "~bootstrap/scss/bootstrap";
// @import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";

.grecaptcha-badge {
  visibility: hidden;
}

.custom-table {
}

.custom-table tbody tr.customer-row {
  height: 60px;
  vertical-align: middle;
  background-color: white;
}

.custom-table tbody tr.customer-row:hover {
  background-color: #f8f9fa;
}

.custom-table thead th {
  padding: 16px 12px;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  background-color: #f8f9fa;
}

.custom-table tbody td {
  padding: 16px 12px;
  font-size: 1.3rem !important;
}

.new-customer-row {
  height: 60px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}
