.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}

.authInputArea {
  flex-grow: 1;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.authlayout_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.authlayout_card-bottom {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.authlayout_card-actions {
  display: flex;
  justify-content: space-between;
}

.authInputArea input:focus {
  border: 2px solid #f96a8b;
}
.input label input:focus + span {
  top: -15px;
  font-size: 10px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fbc02d),
    to(#f96a8b)
  );
  background: linear-gradient(to right, #fbc02d, #f96a8b);
  border-radius: 4px;
  padding: 0 5px;
  color: #fff;
}

.authInputArea p {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(150, 14, 89);
  display: inline-block;
  margin-right: 30px;
  margin-left: 30px;
  outline-width: 1px;
}

.authInputArea h1 {
  padding-bottom: 25px;
}

.custom-card-body {
  background-color: #ffffff;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2a35;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.3rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.card {
  box-shadow: $card-box-shadow !important;
  margin-bottom: 30px !important;
  background-color: #ffffff !important;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px !important;
    line-height: 60px;
  }
}

// Custom Stlye
h3 {
  font-size: 26px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 500;
}

button {
  font-size: 18px;
}

p {
  font-size: 18px;
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}

.agreement_checkbox input[type="checkbox"] {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .agreement_checkbox input[type="checkbox"] {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}

.card {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 30px !important;
  background-color: #ffffff !important;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.btn {
  font-size: 17px !important;
  padding: 5px 10px;
}

.card {
  padding: 20px !important;
}

input {
  padding: 5px 10px !important;
}

.form-label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  padding: 8px 15px !important;
  font-size: 17px !important;
  margin-bottom: 10px;
}

.form-select,
.list-group-item {
  padding: 8px 15px !important;
  font-size: 17px !important;
}

div {
  font-size: 13px !important;
}

h6 {
  font-size: 13px;
}

small {
  font-size: 12px !important;
}

h5 {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

strong {
  font-size: 15px !important;
}

.time-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.segment-list {
  max-height: 170px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.emoji-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.epr_i3a6gx,
.epr_qyh4cg {
  display: none !important;
}

// Add Template

.add-template-modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-template-modal__left {
  flex: 1;
  margin-right: 20px;
}

.template-button-container {
  display: flex;
  justify-content: flex-end;
}

.add-template-modal__left-channel {
  margin-top: 20px;
}

.add-template-modal__left-template {
  margin-top: 20px;
}

.add-template-modal__right {
  flex: 1;
  border-left: 1px solid #ddd;
  padding-left: 20px;
}

.add-template-modal__extras {
  display: flex;
  margin-bottom: 10px;
}

.add-template-modal__emoji-icon {
  cursor: pointer;
  margin-left: 10px;
}

.add-template-modal__select {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.add-template-modal__preview {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.add-template-modal__preview-text {
  font-size: 12px;
}

.add-template-modal__image {
  max-width: 100%;
  max-height: 150px;
  border-radius: 8px;
}

.add-template-modal__variables {
  margin-top: 20px;
}

.audit-table-head {
  border: 1px solid #ddd;
  padding: 8px;
}
.audit-table-body {
  border: 1px solid #ddd;
  padding: 8px;
}

.search-filter-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.search-filter-input {
  padding: 10px 12px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid gray;
}

.audit-table-reset-button {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 25px;
  border-radius: 6px;
  border: none;
  background-color: red;
  color: white;
}
.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}

.authInputArea {
  flex-grow: 1;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.authInputArea input:focus {
  border: 2px solid #f96a8b;
}
.input label input:focus + span {
  top: -15px;
  font-size: 10px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fbc02d),
    to(#f96a8b)
  );
  background: linear-gradient(to right, #fbc02d, #f96a8b);
  border-radius: 4px;
  padding: 0 5px;
  color: #fff;
}

.authInputArea p {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(150, 14, 89);
  display: inline-block;
  margin-right: 30px;
  margin-left: 30px;
  outline-width: 1px;
}

.authInputArea h1 {
  padding-bottom: 25px;
}

.custom-card-body {
  background-color: #ffffff;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2a35;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.3rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.card {
  box-shadow: $card-box-shadow !important;
  margin-bottom: 30px !important;
  background-color: #ffffff !important;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px !important;
    line-height: 60px;
  }
}

// Custom Stlye
h3 {
  font-size: 26px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 500;
}

button {
  font-size: 18px;
}

p {
  font-size: 18px;
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}

.agreement_checkbox input[type="checkbox"] {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .agreement_checkbox input[type="checkbox"] {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}

.card {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 30px !important;
  background-color: #ffffff !important;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.btn {
  font-size: 17px !important;
  padding: 5px 10px;
}

.card {
  padding: 20px !important;
}

input {
  padding: 5px 10px !important;
}

.form-label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  padding: 8px 15px !important;
  font-size: 17px !important;
  margin-bottom: 10px;
}

.form-select,
.list-group-item {
  padding: 8px 15px !important;
  font-size: 17px !important;
}

div {
  font-size: 13px !important;
}

h6 {
  font-size: 13px;
}

small {
  font-size: 12px !important;
}

h5 {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

strong {
  font-size: 15px !important;
}

.time-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.segment-list {
  max-height: 170px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.emoji-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.epr_i3a6gx,
.epr_qyh4cg {
  display: none !important;
}

// Add Template

.add-template-modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-template-modal__left {
  flex: 1;
  margin-right: 20px;
}

.template-button-container {
  display: flex;
  justify-content: flex-end;
}

.add-template-modal__left-channel {
  margin-top: 20px;
}

.add-template-modal__left-template {
  margin-top: 20px;
}

.add-template-modal__right {
  flex: 1;
  border-left: 1px solid #ddd;
  padding-left: 20px;
}

.add-template-modal__extras {
  display: flex;
  margin-bottom: 10px;
}

.add-template-modal__emoji-icon {
  cursor: pointer;
  margin-left: 10px;
}

.add-template-modal__select {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.add-template-modal__preview {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.add-template-modal__preview-text {
  font-size: 12px;
}

.add-template-modal__image {
  max-width: 100%;
  max-height: 150px;
  border-radius: 8px;
}

.add-template-modal__variables {
  margin-top: 20px;
}

.audit-table-head {
  border: 1px solid #ddd;
  padding: 8px;
}
.audit-table-body {
  border: 1px solid #ddd;
  padding: 8px;
}

.search-filter-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.search-filter-input {
  padding: 10px 12px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid gray;
}

.audit-table-reset-button {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 25px;
  border-radius: 6px;
  border: none;
  background-color: red;
  color: white;
}

.page-link {
  font-size: 1.75rem !important;
  padding: 5px 8px;
}
