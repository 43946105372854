.qr-generator {
	max-width: 1200px;
	margin: 0 auto;
	padding: 2rem;
}

.navigation-grid .nav-item-link {
	display: block;
	text-decoration: none;
	color: #666;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	transition: all 0.2s ease;
	height: 100%;
}

.navigation-grid .nav-item-link:hover {
	background-color: #f8f9fa;
	border-color: #dee2e6;
	color: #333;
}

.navigation-grid .nav-item-link.active {
	background-color: #f8f9fa;
	border-color: #0d6efd;
	color: #0d6efd;
}

.navigation-grid .nav-item-link .small {
	font-size: 0.75rem;
	font-weight: 600;
	text-transform: uppercase;
}

.preview-card {
	position: sticky;
	top: 1rem;
}

.preview-container {
	background-color: #fff;
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 1rem;
}

.customizer-section {
	margin-bottom: 1.5rem;
}

.customizer-section h5 {
	color: #666;
	font-size: 0.875rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.download-section .btn {
	padding: 0.75rem 1rem;
}

.download-section .btn small {
	opacity: 0.7;
}

.qr-form-container {
	background-color: #fff;
	border-radius: 8px;
	padding: 1.5rem;
}
