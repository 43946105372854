body {
  max-width: 100vw;
}
.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  height: calc(100vh - 63px);
  position: sticky;
  top: 63px;
  overflow: auto;
  background-color: $sidebarColor;

  .nav-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    padding: 1em 2em !important;
  }
  .sidenav-bg:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $border-radius;
    .nav-link {
      color: white;
    }
  }
  .sidenav-bg .active {
    background-color: $primary;
    border-radius: $border-radius;
    color: white;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;

    top: 0;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}

@media (max-width: 768px) {
  /* Adjust the position of the sidebar area on mobile */
  .sidebarArea {
    top: 64px; /* This moves the sidebar 50 pixels down from the top */
    /* Add any other styles you want to apply only on mobile */
  }

  /* Ensure the close icon is visible */
  .toggle-button {
    display: block; /* Ensure it's not hidden */
    color: #fff; /* Adjust color to make it visible */
    background-color: #333; /* Optional: background color for the button */
    border: none; /* Optional: remove border */
    font-size: 1.2rem; /* Adjust font size for visibility */
    padding: 0.5rem 1rem; /* Add some padding */
  }

  /* Add any other mobile-specific styles here */
}
